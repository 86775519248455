<template>
    <div id="historyListPage">
      <div class="top-nav-bar">
        <van-nav-bar :title="$route.meta.title" @click-left="$router.back()" @click-right="onDelete('')">
          <van-icon class="back-arrow" name="arrow-left"  slot="left" />
          <div class="right" slot="right">
            <i class="icon icon-clear"></i>
            <span class="name">クリア </span>
          </div>
        </van-nav-bar>
      </div>
      <div class="container" >
        <Empty
          v-if="!getLength"
          tip="履歴なし"
        ></Empty>
        <van-list
          v-else
          :finished="getLength > 0"
        >
          <!-- 今日开始 -->
          <div class="section" v-if="detail.todayList.length!==0">
            <div class="section-title">今日</div>
            <div class="section-content">
              <van-row class="list" type="flex">
                <van-col
                  v-for="item in detail.todayList"
                  :key="item.id"
                  :span="span"
                >
                  <router-link
                    :to="{name: 'Detail',query: { id: item.videoId } }"
                    class="list-item"
                  >
                    <ListItem2 :detail ="item">
                      <template slot="right">
                        <i @click.prevent="onDelete(item)" class="icon icon-del"></i>
                      </template>
                    </ListItem2>
                  </router-link>
                </van-col>
              </van-row>
            </div>
          </div>
          <!-- 今日结束 -->

          <!-- 明日开始 -->
          <div class="section" v-if="detail.yesterdayList.length!==0">
            <div class="section-title">昨日</div>
            <div class="section-content">
              <van-row class="list" type="flex">
                <van-col
                  v-for="item in detail.yesterdayList"
                  :key="item.id"
                  :span="span"
                >
                  <router-link
                    :to="{name: 'Detail',query: { id: item.videoId } }"
                    class="list-item"
                  >
                    <ListItem2 :detail ="item">
                      <template slot="right">
                        <i @click.prevent="onDelete(item)" class="icon icon-del"></i>
                      </template>
                    </ListItem2>
                  </router-link>
                </van-col>
              </van-row>
            </div>
          </div>
          <!-- 明日结束 -->

          <!-- 更早开始 -->
          <div class="section" v-if="detail.earlierList.length!==0">
            <div class="section-title">もっと早く</div>
            <div class="section-content">
              <van-row class="list" type="flex">
                <van-col
                  v-for="item in detail.earlierList"
                  :key="item.id"
                  :span="span"
                >
                  <router-link
                    :to="{name: 'Detail',query: { id: item.videoId } }"
                    class="list-item"
                  >
                    <ListItem2 :detail ="item">
                      <template slot="right">
                        <i @click.prevent="onDelete(item)" class="icon icon-del"></i>
                      </template>
                    </ListItem2>
                  </router-link>
                </van-col>
              </van-row>
            </div>
          </div>
          <!-- 更早结束 -->
        </van-list>
      </div>
    </div>
</template>

<script>
import { NavBar } from 'vant';
import { Empty, ListItem2 } from '@/components';
import { mapGetters } from 'vuex';
export default {
  name: 'HistoryList',
  components: {
    'van-nav-bar': NavBar,
    Empty,
    ListItem2
  },
  data () {
    return {
      span: 24,
      detail: {
        earlierList: [], // earlierList表示更早的观看记录
        todayList: [], // todayList表示今天的观看记录
        yesterdayList: [] // yesterdayList表示昨天的观看记录
      }
    };
  },
  computed: {
    ...mapGetters(['htmlWidth']),

    /* 计算三种类别的日期是否有视频 */
    getLength () {
      return Object.values(this.detail).reduce((total, item) => total + item.length, 0);
    }
  },
  created () {
  },
  mounted () {
    this.onResize();

    /* 获取详情列表数据 */
    this.getDetail();
  },
  watch: {
    htmlWidth () {
      this.onResize();
    }
  },
  methods: {

    /* 加载详情数据 */
    getDetail () {
      let { detail } = this;
      this.$http.get('mine/selectRecord').then((res) => {

        /*
          earlierList表示更早的观看记录
          todayList表示今天的观看记录
          yesterdayList表示昨天的观看记录
        */
        let { earlierList, todayList, yesterdayList } = res.data || {};
        detail.earlierList = earlierList || [];
        detail.todayList = todayList || [];
        detail.yesterdayList = yesterdayList || [];
        this.detail = detail;
      });
    },

    /* 删除历史记录的视频 */
    onDelete (item) {
      const { $utils, $http } = this;
      $http.get('mine/removeRecord', {
        params: {
          id: item.id,
          isDelAll: item === ''
        }
      }).then((res) => {
        $utils.successToast({
          message: '削除に成功しました',
          onClose: () => {
            this.getDetail();
          }
        });
      });
    },

    /* 控制大小比例 */
    onResize () {
      const { htmlWidth } = this;
      this.span = 24;
      if (htmlWidth > 1200) {
        this.span = 8;
      } else if (htmlWidth > 992) {
        this.span = 12;
      }
    }

  }
};
</script>

<style scoped lang="less">
  #historyListPage {
    display: flex;
    flex-direction: column;
    height: 100%;

    /deep/
    .top-nav-bar {
      .van-nav-bar {
        .right {
          display: flex;
          align-items: center;

          .icon {
            width: 32px;
            height: 32px;

            &.icon-clear {
              background-image: url("~@/assets/images/icon_clear_01.png");
            }
          }

          .name {
            margin-left: 12px;
            font-size: 24px;
            line-height: 32px;
          }
        }
      }
    }

    .container {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;

      .section {
        .section-title {
          padding: 26px 28px 20px;
          font-size: 30px;
          line-height: 40px;
          color: #fff;
        }

        .section-content {
          .list {
            .list-item {
              position: relative;
              width: 100%;
              padding: 28px;

              &::after {
                position: absolute;
                bottom: 0;
                left: 28px;
                width: calc(100% - 56px);
                height: 1px;
                content: '';
                background-color: rgba(255, 255, 255, 0.05);
              }

              .icon-del {
                width: 36px;
                height: 36px;
                background-image: url("~@/assets/images/icon_delete_01.png");
              }
            }
          }
        }
      }
    }
  }
</style>